
//
// VARIABLES
//

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

// Grays
$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #666;
$lightGray: #eee;
$white: #fff;
