
//
// DEVICES
//

.device {
  position: relative;
  background-size: cover;
}

.device .app-screenshot {
  position: absolute;
  width: 240px;
  height: auto;
}

.device .video-container {
  position: absolute;
  width: 240px;
  height: 426.66px;
  padding-bottom: 0;
    margin-left: 24px;
    margin-top: 90.67px;
    overflow: hidden;
}
// Video

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

//
// iPhone SE Silver Vertical
//

.device.iphone-se-silver-vertical {
  width: 288px;
  height: 608px;
  background-image: url(../../images/devices/iphone-se-silver-vertical.png);
}
.device.iphone-se-silver-vertical .app-screenshot {
  top: 91px;
  left: 25px;
  width: 240px;
}

//
// iPhone SE Space Gray Vertical
//

.device.iphone-se-space-gray-vertical {
  width: 288px;
  height: 608px;
  background-image: url(../../images/devices/iphone-se-space-gray-vertical.png);
}
.device.iphone-se-space-gray-vertical .app-screenshot {
  top: 91px;
  left: 25px;
  width: 240px;
}

//
// iPod Touch Blue Vertical
//

.device.ipod-touch-blue-vertical {
  width: 291px;
  height: 614px;
  background-image: url(../../images/devices/ipod-touch-blue-vertical.png);
}
.device.ipod-touch-blue-vertical .app-screenshot {
  top: 87px;
  left: 23px;
  width: 246px;
}


.flexslider .slides > li {
    top: -1px;
    left: -40px;
}