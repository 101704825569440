@import "variables";

//
// BASE
//

html,
body {
  min-height: 100%;
}

body {
  
  background-color: #989898;
  

  
  background: linear-gradient(rgba(59, 61, 64, 0.5), rgba(59, 61, 64, 0.5)), url(../..//images/backgrounds/58-usersthink-stock-image-web.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  

  
  color: white;
  

  font-size: 18px;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

a {
  transition: all .3s;
}



//
// LAYOUT / SECTIONS
//

.container {
  max-width: 980px;
}

.main {
  position: relative;
  min-height: 100%;
  padding-top: 70px;
  padding-bottom: 60px;
}

.footer {
  padding-bottom: 50px;
}

//
// Marketing
//

.marketing {
  text-align: center;
}

.app-meta {
  margin-bottom: 20px;
  font-size: 32px;
}

.app-icon {
  display: block;
  margin: 0 auto 10px;
  border-radius: 15px;
  width: 92px;
}

.marketing-desc {
  margin: 0 auto 20px;
  max-width: 450px;
  white-space: pre-wrap;
  font-weight: 500;
}

//
// Download Buttons
//

.download-buttons {
  margin-bottom: 20px;
}

.download-btn {
  margin-right: 20px;
  margin-bottom: 20px;
  border: none;
  background-color: #0191FF;
  color: #fff;

  
  background-color: #0191FF;
  

  
  color: white;;
  
}

.download-btn:focus,
.download-btn:hover {
  background-color: darken(#0191FF, 20%);
  color: #fff;

  
  background-color: darken(#0191FF, 20%);
  

  
  color: white;;
  
}

//
// Social
//

.social {
  text-align: center;
}

.social-links {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.social-links li {
  display: inline-block;
  margin: 12px;
}


.social-links a {
  color: white;
}

.social-links a:focus,
.social-links a:hover {
  color: darken(white, 30%);
}


//
// Device
//

.device {
  margin: auto;
}

//
// large screens
//

@media (min-width: 768px) {
  .marketing {
    text-align: left;
  }

  .app-icon {
    display: inline;
  }

  .marketing-desc {
    text-align: left;
  }
}

//
// more imports
//

@import "devices";
